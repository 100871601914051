.services {
  background-image: linear-gradient(0deg, rgba(64, 64, 64, 0.46), rgba(64, 64, 64, 0.46)), url("../../../../assets/img/bcgs/about_bcg.png");
  background-position: top;
  background-size: cover;
  background-attachment: fixed;
  padding: 6rem 0; }
  .services__infoBox {
    background-image: linear-gradient(95.47deg, #2A2A34 0.68%, rgba(42, 42, 52, 0.58) 98.32%);
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
    padding: 4rem 7rem; }
    .services__infoBox__heading {
      font-size: 2.6rem;
      margin-bottom: 4rem; }
    .services__infoBox__services {
      display: flex;
      justify-content: center;
      gap: 3rem; }
      .services__infoBox__services__item {
        background-color: #D4C5B0;
        color: #404040;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 4rem 5rem;
        width: 20%;
        height: 34rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all .4s; }
        .services__infoBox__services__item__ico {
          float: right;
          width: 8rem; }
        .services__infoBox__services__item__heading {
          font-size: 2.4rem;
          margin-top: 8rem; }
        .services__infoBox__services__item__text {
          font-size: 1.4rem;
          line-height: 1.4;
        }

.services__infoBox__services__item__link {
  background: none;
  border: none;
  color: #404040;
  font-size: 1.4rem;
  font-weight: 700;
  transition: all .4s;
  cursor: pointer;
  text-align: left;

  &:hover {
  opacity: 0.8;
}
}

.services__infoBox__services__item:hover {
  transform: scale(1.1);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25); 
}


@media (max-width: 1500px) {
  .services__infoBox__services__item {
    width: 30%; }
  .principles__box {
    width: 100%; }
}

@media (max-width: 1200px) {
  html {
    font-size: 50%; }
  .principles__box__item__fig {
    font-size: 16rem;
    line-height: 16rem; }
  .contacts__box {
    padding: 0;
    gap: 3rem; }
  .contacts__box__formBox {
    width: 50%; }
  .contacts__box__info {
    width: 50%; }
  .contacts__box__info__map {
    width: 100%; } }

@media (max-width: 1000px) {
  .principles__box__item {
    padding: 1.6rem; }
  .testimonails__layout {
    width: 100%; } 
}

@media (max-width: 800px) {
  .about {
    padding: 6rem 4rem; }
  .about__diploms__item__pic1,
  .about__diploms__item__pic2 {
    width: 22rem; }
  .about__infoBox__info {
    width: 95%; }
  .services__infoBox {
    padding: 4rem; }
  .services__infoBox__services__item {
    width: 50%; }
  .principles,
  .footer,
  .testimonails {
    padding: 4rem; }
  .principles__box__item__text {
    font-size: 1.4rem; }
  .principles__box__item__fig {
    font-size: 12rem;
    line-height: 12rem; }
  .principles__box {
    gap: 2rem; }
  .contacts {
    padding: 4rem; } }

@media (max-width: 600px) {
  .about__diploms {
    flex-direction: column;
    align-items: center; }
  .about__diploms__item__pic1,
  .about__diploms__item__pic2 {
    width: 30rem; }
  .services__infoBox__services__item {
    padding: 3rem; }
  .principles__box {
    flex-direction: column;
    align-items: center; }
  .principles__box__item {
    width: 30rem; }
  .contacts__box {
    flex-direction: column;
    align-items: center; }
  .contacts__box__info,
  .contacts__box__formBox {
    width: 100%; } }

@media (max-width: 500px) {
  .services__infoBox__services {
    flex-direction: column;
    align-items: center; }
  .services__infoBox__services__item {
    width: 80%;
    padding: 4rem; }
  }

@media (max-width: 400px) {
  .about {
    padding: 4rem 2rem; }
  .about__infoBox__info {
    width: 100%;
    padding: 3rem;
    font-size: 2rem; }
  .about__infoBox__info__text {
    margin-bottom: 2rem; }
  .callme__form {
    flex-direction: column;
    align-items: center; }
  .callme__form__input {
    width: 100%; }
  .services__infoBox,
  .principles,
  .contacts,
  .footer,
  .testimonails {
    padding: 4rem 2rem; }
  .footer {
    flex-direction: column;
    align-items: center;
    gap: 2rem; }
  .footer__info__link {
    font-size: 2rem; }
  .footer__nav__link {
    font-size: 3rem; } }
