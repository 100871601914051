.footer {
  background-color: #2A2A34;
  padding: 2rem 7rem;
  display: flex;
  justify-content: space-between; }
  .footer__info {
    display: flex;
    flex-direction: column;
    gap: 1rem; }
    .footer__info__link {
      color: #fff;
      font-size: 1.4rem;
      transition: all .4s; }
      .footer__info__link:hover {
        opacity: 0.8; }
  .footer__nav {
    display: flex;
    justify-content: flex-end;
    gap: 3rem;
    align-items: center; }
    .footer__nav__link {
      font-size: 2rem;
      color: #fff;
      transition: all .4s; }
      .footer__nav__link:hover {
        opacity: 0.8; }

.footer__nav__link__socioIco {
  width: 35px;
  height: 35px;
}
@media (max-width: 600px) {
  .footer {
    padding: 1rem;
  }
}

@media (max-width: 400px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    gap: 5rem; }
  .footer__info__link {
    font-size: 2rem; }
  .footer__nav__link {
    font-size: 2rem; } 
  }
