.consultation {
  background-color: #2A2A34;
  padding: 4rem 7rem; }
  .consultation__heading {
    font-size: 2.6rem;
    margin-bottom: 4rem; }
  .consultation__box {
    display: flex;
    justify-content: center;
    gap: 4rem;
    width: 80%;
    margin: auto; }
    .consultation__box__item {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      background-color: #97784C;
      padding: 2rem 3rem;
      width: 35%;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);
      border-radius: 3px; }
      .consultation__box__item__fig {
        font-size: 20rem;
        line-height: 18rem; }
      .consultation__box__item__text {
        padding-top: 1rem;
        line-height: 1.4;
        font-size: 1.8rem; }



@media (max-width: 1500px) {
  .consultation__box {
    width: 100%; }
}

@media (max-width: 1200px) {
  .consultation__box__item__fig {
    font-size: 16rem;
    line-height: 16rem; }
}

@media (max-width: 1000px) {
  .consultation__box__item {
    padding: 1.6rem; }
}

@media (max-width: 800px) {
  .consultation {
    padding: 4rem; }
  .consultation__box__item__text {
    font-size: 1.4rem; }
  .consultation__box__item__fig {
    font-size: 12rem;
    line-height: 12rem; }
  .consultation__box {
    gap: 2rem; }
}

@media (max-width: 600px) {
  .consultation__box {
    flex-direction: column;
    align-items: center; }
  .consultation__box__item {
    width: 30rem; }
}

@media (max-width: 400px) {
  .consultation{
    padding: 4rem 2rem;
  }
}
