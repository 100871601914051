.testimonails {
  padding: 6rem 7rem;
  background: linear-gradient(269.84deg, #303041 0.14%, #252532 99.86%); }
  .testimonails__heading {
    font-size: 2.6rem;
    margin-bottom: 4rem; }
  .testimonails__layout {
    width: 80%;
    overflow-x: auto;
    margin: auto; }
  .testimonails__box {
    display: flex;
    gap: 2rem;
    width: 240rem;
    padding: 1rem 0; }
    .testimonails__box__item {
      width: 80rem;
      max-height: 34rem;
      overflow-y: auto;
      background-color: #97784C;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      padding: 3rem; }
      .testimonails__box__item__header {
        font-size: 2rem;
        margin-bottom: 2rem; }
      .testimonails__box__item__text {
        font-size: 1.4rem;
        margin-bottom: 1.4rem; }



@media (max-width: 1500px) {
  .services__infoBox__services__item {
    width: 30%; }
  .principles__box {
    width: 100%; }
}

@media (max-width: 1200px) {
  html {
    font-size: 50%; }
  .principles__box__item__fig {
    font-size: 16rem;
    line-height: 16rem; }
  .contacts__box {
    padding: 0;
    gap: 3rem; }
  .contacts__box__formBox {
    width: 50%; }
  .contacts__box__info {
    width: 50%; }
  .contacts__box__info__map {
    width: 100%; } }

@media (max-width: 1000px) {
  .callme__form {
    width: auto; }
  .callme__text {
    font-size: 1.4rem; }
  .principles__box__item {
    padding: 1.6rem; }
  .testimonails__layout {
    width: 100%; } 
}


@media (max-width: 800px) {
  .about {
    padding: 6rem 4rem; }
  .about__diploms__item__pic1,
  .about__diploms__item__pic2 {
    width: 22rem; }
  .about__infoBox__info {
    width: 95%; }
  .services__infoBox {
    padding: 4rem; }
  .services__infoBox__services__item {
    width: 50%; }
  .principles,
  .footer,
  .testimonails {
    padding: 4rem; }
  .principles__box__item__text {
    font-size: 1.4rem; }
  .principles__box__item__fig {
    font-size: 12rem;
    line-height: 12rem; }
  .principles__box {
    gap: 2rem; }
  .contacts {
    padding: 4rem; } }

@media (max-width: 600px) {
  .about__diploms {
    flex-direction: column;
    align-items: center; }
  .about__diploms__item__pic1,
  .about__diploms__item__pic2 {
    width: 30rem; }
  .services__infoBox__services__item {
    padding: 3rem; }
  .principles__box {
    flex-direction: column;
    align-items: center; }
  .principles__box__item {
    width: 30rem; }
  .contacts__box {
    flex-direction: column;
    align-items: center; }
  .contacts__box__info,
  .contacts__box__formBox {
    width: 100%; } }

@media (max-width: 500px) {
  .services__infoBox__services {
    flex-direction: column;
    align-items: center; }
  .services__infoBox__services__item {
    width: 80%;
    padding: 4rem; }
  }

@media (max-width: 400px) {
  .about {
    padding: 4rem 2rem; }
  .about__infoBox__info {
    width: 100%;
    padding: 3rem;
    font-size: 2rem; }
  .about__infoBox__info__text {
    margin-bottom: 2rem; }
  .callme__form {
    flex-direction: column;
    align-items: center; }
  .callme__form__input {
    width: 100%; }
  .services__infoBox,
  .principles,
  .contacts,
  .footer,
  .testimonails {
    padding: 4rem 2rem; }
  .footer {
    flex-direction: column;
    align-items: center;
    gap: 2rem; }
  .footer__info__link {
    font-size: 2rem; }
  .footer__nav__link {
    font-size: 3rem; } }
