.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #00000079;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.modal {
  width: 80%;
  background-color: #2A2A34;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 4px 4px 8px rgba(68, 68, 68, 0.25);
  border-radius: 5px;
  color: #202020;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  max-height: 90%;
  overflow-y: auto;
}

.closeBtn {
  width: 3rem;
  height: 3rem;
  right: 5%;
  position: fixed;
  cursor: pointer;
}

.aboutAdv {
  background-color: #2A2A34;
  color: #fff;
  padding: 2rem 6rem;
  padding-left: 8rem;
}

.servicePage__text {
  margin-bottom: 4rem;
  max-width: 80rem;
  font-size: 1.8rem;
  line-height: 1.8;
}

.servicePage__heading {
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 0;
}

.servicePage__list {
  padding-inline-start: 3rem;
  margin-top: 1rem;
}