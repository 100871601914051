.header {
  margin-bottom: 10rem;
}
.header__boxBcg {
  height: 110vh;
  width: 80%;
  background: linear-gradient(248.74deg, #3B3B4B 0%, #2A2A34 100.71%);
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: -1;
  overflow: hidden;
}

.header__boxBcg2 {
  height: 200vh;
  width: 100%;
  background-color: #2A2A34;
  position: absolute;
  z-index: -2;
  overflow: hidden; }

.header__nav {
  padding: 2rem 6rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8rem;
  z-index: 2;
}

.header__nav__flexItem {
  display: flex;
  gap: 2.8rem;
}
  .header__nav__item__ico {
    position: relative;
    display: inline-block;
    top: 0.4rem;
    width: 1.7rem;
    margin-right: 0.5rem; }
  .header__nav__item__link {
    color: #fff;
    font-size: 2rem;
    text-decoration: underline;
    transition: all .4s; }
    .header__nav__item__link:hover {
      opacity: 0.8; }

.header__nav__item__socioIco {
  width: 35px;
  height: 35px;
}
.header__heading {
  padding-right: 20rem; 
}

.header__heading__item {
    background-image: linear-gradient(0deg, rgba(212, 197, 176, 1), rgba(212, 197, 176, 0.78)), url("../../../../assets/img/bcgs/head_bcg1.png");
    background-position: top;
    background-size: cover;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    padding: 3rem 4rem 3rem 6rem;
    width: 120rem;
    color: #404040;
}
    
.header__heading__item__heading {
      font-size: 3rem;
      letter-spacing: -1px;
      margin-bottom: 0.4rem;
      transition: all .4s;
      animation-name: moveInLeft;
      animation-duration: 1s; 
}
.header__heading__item__heading__title {
  font-size: 6rem;
}
.header__heading__item__text {
  font-size: 1.6rem;
  width: 60rem;
  // font-weight: 600;
  line-height: 2.6rem;
  margin-top: 3rem;
}

.header__heading__item__descr {
      font-size: 1.2rem;
}

.header__heading__text {
    width: 70rem;
    padding: 3rem 4rem 2rem 4rem;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.4;
}

.header__heading__photo {
  position: absolute;
  width: 42rem;
  margin: 8rem 8rem 0 0;
  right: 0;
}

.header__info {
  padding: 2rem 0;
}

.header__info__adv {
    padding: 2rem 0 2rem 0;
    align-self: baseline;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    animation-name: moveInBottom;
    animation-duration: 1s;
}
    
.header__info__adv__free {
      font-size: 4rem;
      text-align: center;
      font-weight: 700;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding-bottom: 5rem;
}

.header__info__adv__tel {
      font-size: 4rem;
      text-align: center;
      font-weight: 700;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
      .header__info__adv__tel__link {
        color: #fff; }
    .header__info__adv__nav {
      font-size: 2.2rem;
      display: flex;
      justify-content: center;
      gap: 2rem;
      animation-name: moveInBottom;
      animation-duration: 1s; }
      .header__info__adv__nav__link {
        color: #D4C5B0;
        text-decoration: none;
        border-left: solid #D4C5B0 2px;
        border-right: solid #D4C5B0 2px;
        border-top: solid transparent 2px;
        border-bottom: solid transparent 2px;
        padding: 0.6rem 2rem;
        transition: all .4s;
        font-weight: 700; }
        .header__info__adv__nav__link:hover {
          border: solid #D4C5B0 2px; }

.header__info__servs {
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  margin-bottom: 2rem;
}

.header__info__servs__row {
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.header__info__servs__list__item__link {
  color: #fff;
  font-size: 1.8rem;
}

.header__info__services {
  animation-name: moveInRight;
  animation-duration: 1s;
  display: flex;
  align-items: flex-end;
}
.header__info__services__list {
  align-self: flex-start;
}
.header__info__services__list__item {
  padding: 0 7rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff;
  width: 70rem;
  margin-bottom: 1rem;

  &__text {
    margin-top: 1rem;
    position: absolute;
    z-index: 10;
    background: #97784C;
    width: 70rem;
    padding: 1.4rem 2rem;
    line-height: 1.5;
    font-size: 1.4rem;
    border-radius: 5px;
  }
}
.header__info__services__list__item__link {
      transition-duration: 0.8s;
      color: #fff;
}

.header__info__services__list__item__link:hover {
  opacity: 0.8;
}

.header__info__desclimer {
  text-align: right;
  padding-right: 2.5rem;
  font-size: 1rem;
}


@media (max-width: 1500px) {
  .header__boxBcg {
    height: 130vh;
  }
  .header__heading__photo {
    margin-right: 3rem;
    width: 45rem;
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 50%; 
  }
  .header__heading {
    padding-right: 10rem; 
  }
  .header__heading__photo {
    width: 30rem;
  }
}

@media (max-width: 1000px) {
  .header__heading__item {
    width: 80rem; }
}

@media (max-width: 900px) {
  .header__heading__item__text {
    width: 45rem;
  }
  .header__info__services__list {
    width: 50rem;
    font-size: 1.4rem;
  }
  .header__info__services__list__item__text {
    width: 60rem;
  }
}

@media (max-width: 800px) {
  .header__nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .header__nav__flexItem {
    flex-wrap: wrap;
  }
  .header__info__servs {
    margin-bottom: 4rem;
  } 
  .header__info__adv {
    padding: 0;
    margin-bottom: 3rem; }
  .header__heading__photo {
    margin-top: 14rem;
    width: 26rem; 
  }
  .header__info__services__list {
    padding: 0;
    width: 40rem;
  }
  .header__info__services__list__item__text {
    width: 50rem;
  }
  .header__heading {
    padding-right: 4rem; }
  .header__heading__text,
  .header__heading__item,
  .header__heading__item__heading {
    font-size: 2.4rem; }
  
    .header__info {
    padding: 2rem 0 2rem 0;
    flex-direction: column;
  }
  .header__info__services {
    align-self: flex-end;
    font-size: 2rem; }
  .header__info__adv__free {
    margin-bottom: 3rem; }
  .header__heading__item {
    width: auto; }
}

@media (max-width: 600px) {
  .header__nav {
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    gap: 3rem; }
  .header__info__services {
    width: auto;
    flex-direction: column-reverse;
    align-items: center;
  }
  .header__info__services__list {
    width: 100%;
    padding-left: 2rem;
    font-size: 1.8rem;
  }
  .header__info__services__list__item {
    padding: 0;
    width: 100%;
    margin-bottom: 1.6rem;
  }
  .header__info__services__list__item__text {
    width: 90%;
  }
  .header__info__adv__free {
    margin-bottom: 0;
    padding-bottom: 4rem;
  }
  .header__heading__text {
    width: auto; }
  .header__heading__item__heading {
    font-size: 3rem; }
  .header__heading__photo {
    width: 24rem;
  }
  .header__heading {
    padding-right: 2rem; }
  
  .header__nav__flexItem {
    gap: 5rem;
  }
}

@media (max-width: 500px) {
  .header {
    margin-bottom: 0;
    padding-bottom: 8rem;
    background-color: #2A2A34;
  }
  .header__heading__photo {
    display: none;
  }
  .header__heading__item__text {
    width: 38rem;
  }

  .header__info__servs__row {
    flex-direction: column;
    align-items: center;
    gap: 1.4rem;
    text-align: center;
  } 
}

@media (max-width: 400px) {
  .header__boxBcg {
    display: none; }
  .header__info,
  .header__heading__item {
    padding: 2rem; }
  .header__info__adv__nav {
    gap: 1rem; }
  .header__info__adv__nav__link {
    padding: 0.6rem 1rem; }
  .header__heading__text {
    line-height: 1.4;
    padding: 2rem;
  }
}
