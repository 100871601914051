.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #00000079;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.modal {
  width: 80%;
  background-color: #2A2A34;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 4px 4px 8px rgba(68, 68, 68, 0.25);
  border-radius: 5px;
  color: #202020;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  max-height: 90%;
  overflow-y: auto;
}

.closeBtn {
  width: 3rem;
  height: 3rem;
  right: 5%;
  position: fixed;
  cursor: pointer;
}

.aboutAdv {
  background-color: #2A2A34;
  color: #fff;
  padding: 2rem 6rem;
  padding-left: 8rem;
}

.aboutAdv__header {
  font-size: 2.4rem;
  padding: 0.2rem 5rem;
}
.aboutAdv__header:first-child {
  padding-top: 3rem;
}
.aboutAdv__header__link {
  color: #fff;
  text-decoration: none;
}
.aboutAdv__text {
  margin-bottom: 1rem;
  max-width: 70rem;
  font-size: 1.6rem;
  line-height: 1.6;
}

.aboutAdv__header2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.aboutAdv__services {
  padding-inline-start: 3rem;
  font-size: 1.6rem;
  margin-bottom: 4rem;  
}

.aboutAdv__services__item {
  margin-bottom: 0.6rem;
  max-width: 70rem;
  line-height: 1.6;
}

.aboutAdv__flexBox {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
  gap: 2rem;
}

@media (max-width: 900px) {
  .modal {
    width: 90%;
  }
  .closeBtn {
    right: 8%;
  }
}

@media (max-width: 900px) {
  .aboutAdv {
    padding: 2rem 3rem;
  }
}

@media (max-width: 500px) {
  .modal {
    width: 96%;
  }
  .aboutAdv {
    padding: 1rem 2rem;
  }
}