summary {
  cursor: pointer;
}

@keyframes moveInLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0; }
  80% {
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInRight {
  0% {
    transform: translateX(100px);
    opacity: 0; }
  80% {
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInBottom {
  0% {
    transform: translateY(100px);
    opacity: 0; }
  80% {
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.margin-bottom-small {
  margin-bottom: 1.4rem; }

.margin-bottom-medium {
  margin-bottom: 2.2rem; }
