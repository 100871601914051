.tgScreen {
  background-color: #2A2A34;
  padding: 4rem 10rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6rem;

  &__icoBox {
    &__ico{
      width: 8.7rem;
      height: 8.7rem;
      z-index: 8;
      animation: wave  1.5s infinite linear;
    }
    &__ico2{
      width: 8.7rem;
      height: 8.7rem;
      position: absolute;
      z-index: 9;
      animation: wave  1.5s infinite linear;
      animation-delay: 0.5s;
    }
    &__ico3{
      width: 8.7rem;
      height: 8.7rem;
      position: absolute;
      z-index: 10;
      animation: wave  1.5s infinite linear;
      animation-delay: 1s;
    }
  }

  &__textBox {
    font-size: 2.4rem;
    max-width: 67rem;
  }
}

@keyframes wave {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0.1;
  }
}

@media (max-width: 530px) {
  .tgScreen {
    padding: 3rem;
    gap: 3rem;
  }

  .tgScreen__textBox {
    font-size: 1.8rem;
  }
}