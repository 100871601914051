
.contacts {
  background-image: linear-gradient(90.26deg, #2A2A34 0.23%, rgba(42, 42, 52, 0.25) 99.79%), url("../../../../assets/img/bcgs/contacts_bcg.png");
  background-position: top;
  background-size: cover;
  background-attachment: fixed;
  padding: 4rem 7rem 8rem; }
  .contacts__heading {
    font-size: 2.6rem;
    margin-bottom: 4rem; }
  .contacts__box {
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    padding: 0 10rem; }
    .contacts__box__info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 1.8rem;
      width: 40%; }
      .contacts__box__info__link {
        color: #fff; }
      .contacts__box__info__map {
        width: 80%;
        height: 283px;
        border-radius: 5px;
        filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.25)); }
    .contacts__box__formBox {
      font-size: 1.8rem;
      width: 40%; }
      .contacts__box__formBox__form {
        display: flex;
        flex-direction: column;
        gap: 2rem; }
        .contacts__box__formBox__form__inp {
          border: none;
          background: #FFFFFF;
          border-radius: 5px;
          padding: 1rem 2rem;
          font-size: 1.6rem;
          box-sizing: inherit; }
          .contacts__box__formBox__form__inp:focus {
            outline: none; }
        .contacts__box__formBox__form__txtarea {
          border: none;
          background: #FFFFFF;
          border-radius: 5px;
          padding: 1rem 2rem;
          font-size: 1.6rem;
          box-sizing: inherit; }
          .contacts__box__formBox__form__txtarea:focus {
            outline: none; }
        .contacts__box__formBox__form__btn {
          border: none;
          background: #D4C5B0;
          box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.15);
          border-radius: 5px;
          color: #404040;
          padding: 1rem 2rem;
          font-weight: 700;
          font-size: 1.6rem;
          cursor: pointer;
          transition: all .4s;
          box-sizing: inherit;
          width: 20rem;
          align-self: center;
          background-color: #97784C;
          color: #fff; }
          .contacts__box__formBox__form__btn:hover {
            box-shadow: inset 0px 2px 5px rgba(53, 53, 53, 0.3); }
      .contacts__box__formBox__desc {
        font-size: 1.2rem;
        text-align: center; }
        .contacts__box__formBox__desc__link {
          color: #fff; }


@media (max-width: 1200px) {
  .contacts__box {
    padding: 0;
    gap: 3rem; }
  .contacts__box__formBox {
    width: 50%; }
  .contacts__box__info {
    width: 50%; }
  .contacts__box__info__map {
    width: 100%; } 
}

@media (max-width: 800px) {
  .principles,
  .footer,
  .testimonails {
    padding: 4rem; }
  .contacts {
    padding: 4rem; } 
}

@media (max-width: 600px) {
  .services__infoBox__services__item {
    padding: 3rem; }
  .principles__box {
    flex-direction: column;
    align-items: center; }
  .principles__box__item {
    width: 30rem; }
  .contacts__box {
    flex-direction: column;
    align-items: center; }
  .contacts__box__info,
  .contacts__box__formBox {
    width: 100%; } 
}

@media (max-width: 500px) {
  .services__infoBox__services {
    flex-direction: column;
    align-items: center; }
  .services__infoBox__services__item {
    width: 80%;
    padding: 4rem; }
}

@media (max-width: 400px) {
  .services__infoBox,
  .principles,
  .contacts,
  .footer,
  .testimonails {
    padding: 4rem 2rem; }
  .footer {
    flex-direction: column;
    align-items: center;
    gap: 2rem; }
  .footer__info__link {
    font-size: 2rem; }
  .footer__nav__link {
    font-size: 3rem; } }
